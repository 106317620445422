import * as React from "react"
import { Helmet } from "react-helmet";

import Header from "../templates/header";
import Footer from "../templates/footer";

// markup
const PrivacyPolicy = () => {
  return (
    <div>
    <Helmet>
      <title>Cloto Privacy Policy</title>
    </Helmet>
    <Header></Header>
    <main>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto">

<h1 className="text-4xl mt-4 mb-4">Privacy Policy</h1>

<p>
Cloto, Inc. (the “Company”, “we”, “us”, “our”) is always committed to protecting
the privacy of users/guests and their personal information (unrelated to
business designation and affiliation) in its possession and adheres to
applicable privacy laws.
</p>

<h2 className="text-3xl mt-3 mb-3">Application</h2>

<p className="mb-2">
The undermentioned privacy policy outlined is an overview of our privacy
procedures regulating the oversight of our Product(s) and Service(s) but
inapplicable to personal information related to current, past or present
contractors or employees of Cloto, Inc.
</p>

<h2 className="text-3xl mt-3 mb-3">Opt-out Process</h2>

<p className="mb-2">
We may collect, use, disclose and retain personal information for the following purposes:
</p>

<ul className="list-disc list-outside ml-8 mb-2">
    <li>to open and maintain an account with us;</li>
    <li>to offer and provide licenses, products and services;</li>
    <li>to process orders for products/services;</li> 
    <li>to process payments;</li>
    <li>to carry out customer surveys, in order to evaluate the needs, wants and
        satisfaction levels of our customers and to analyze and manage our
        business;</li>
    <li>to provide and administer services and monitor your purchases, fees paid
        and payable, payment history, parties to transactions, payments and
        payment card usage;</li>
    <li>to verify your identity as an authorized user concerning any accounts,
        and to implement, carry out and maintain security measures aimed at
        protecting our customers from identity theft, fraud and unauthorized
        access to accounts;</li>
    <li>to comply with legal or regulatory requirements (including those related
        to security);</li>
    <li>to promote and market products and services offered by us;</li>
    <li>to provide for the purchase and license to online products/services for
        purchase;</li>
    <li>to respond to inquiries from you;</li>
</ul>

<p className="mb-2">
Except as required for the foregoing, or as required or permitted by applicable
law, Cloto does not disclose personal information to third parties without your
prior consent.
</p>

<h2 className="text-3xl mt-3 mb-3">Information collected when you use the site</h2>

<p className="mb-2">
In certain circumstances the personal information we collect and maintain may
include:
</p>

<ul className="list-disc list-outside ml-8 mb-2">
    <li>your name, address, telephone number, email address or other information
        to contact or identify you;</li>
    <li>information about the goods or services provided to or by you;</li>
    <li>information about your transactions with us, including bills, credit
        history, payment preference, billing and credit card information, and
        other details and preferences;</li>
    <li>information from communications with you, including your feedback and
        requests for customer care;</li>
    <li>where required by law to do so;</li>
    <li>site activity information and cookies (see below)</li>
    <li>location information of your mobile device when any one of our Apps is
        open on your device</li>
    <li>voluntary information provided by you, which may include reviews and
        ratings, referrals, special order instructions, feedback, and other
        actions performed on the Website or App</li>
</ul>

<h2 className="text-3xl mt-3 mb-3">Consent</h2>

<p className="mb-2">
If you choose to voluntarily submit personal information to us, we will consider
that you have consented to our collection, use and disclosure of this personal
information for purposes reasonably related to your providing the information
and for the purposes set out in this Policy.If you choose to voluntarily submit
personal information to us, we will consider that you have consented to our
collection, use and disclosure of this personal information for purposes
reasonably related to your providing the information and for the purposes set
out in this Policy.Where we use the services of third parties in our business
they may provide us with your personal information. In such a situation, we will
make reasonable efforts to have such parties assure us that this provision of
your information is permitted.Subject to the above, we will usually ask for your
consent when we collect your personal information. Sometimes this may happen
after collection but prior to our use or disclosure of your personal information.
If we plan to use or disclose your personal information for a purpose not
disclosed in this Policy or elsewhere to you, we will make efforts to let you
know about that purpose before use or disclosure. We may utilize the services of
third-party service providers and third-party independent courier contractors.
We may share your information to facilitate the Service. This may include
sending emails or text notifications, processing payments, placing orders,
sending delivery instructions, and providing superior customer service. We
require that our third-parties commit to use this information for the sole
purpose of fulfilling the Service.You may modify or revoke your consent at any
time, with reasonable notice, subject to applicable laws and contracts signed
by you, by sending a written message to our Privacy Officer. In some
circumstances, a modification or revocation of consent may limit or prevent us
from providing products or services to, or acquiring products or services from
you. We assume, unless you tell us otherwise, that by continuing to deal with us
after having had this Policy made available to you that you consent to the
collection, use and disclosure of your personal information as set out and for
the purposes described in this Policy, where such consent is required by law.
</p>

<h2 className="text-3xl mt-3 mb-3">Use of Cookies</h2>

<p className="mb-2">
<strong>What are Cookies?:</strong> we use a browser feature called a cookie to collect
information anonymously and track user patterns on the site. A cookie is a small
text file that is placed on your hard disk by a web site and contain a unique
identification number that identifies your browser, but not you, to our
computers each time you visit the site. Cookies tell us which pages of our web
site are visited and by how many people. The information that cookies collect
includes the date and time of your visit, your registration information, session
identification number, and your navigational history and preferences.
</p>
<p className="mb-2">
<strong>How to refuse cookies:</strong> The use of cookies is an industry standard and many major
browsers are initially set up to accept them. You can reset your browser to
either refuse to accept all cookies or to notify you when you have received a
cookie. However, if you refuse to accept cookies, you may not be able to use
some of the features available on the site.
</p>
<p className="mb-2">
<strong>Third-party cookies:</strong> Some third-party services providers may use cookies on our
site. We have no control over those cookies and they are not covered by this
privacy policy. Cookies allow us to identify registered users when they return
to the site, eliminating the need for you to repeatedly enter the same
information.
</p>

<h2 className="text-3xl mt-3 mb-3">Accuracy and Completeness</h2>

<p className="mb-2">
When we collect, use or disclose personal information, we will make reasonable
efforts to ensure that it is accurate, up to date, and complete. This may
involve requesting further information or updates from you. We do request that
you advise us of changes to your personal information so that our records may
remain current.
</p>

<h2 className="text-3xl mt-3 mb-3">Retention and Destruction</h2>

<p className="mb-2">
The law allows us, for legal or business purposes, to retain personal
information for as long as is reasonable. Upon expiry of an appropriate
retention period, bearing in mind reasonable legal and business requirements,
personal information will either be destroyed in a secure manner or made
anonymous. You should be aware that there may be legally required minimum
retention periods, which we must and do observe.Should any consent, where
consent is required, to our collection, use, disclosure or retention of
personal information be revoked, the law also allows us to continue to retain
the information for as long as is reasonable for legal or business purposes. In
the event that revocation of any required consent may have consequences to the
individual concerned, we will advise the individual of the consequences of
revoking their consent where it is reasonable in the circumstances to do so.
</p>

<h2 className="text-3xl mt-3 mb-3">Security of Personal Information</h2>

<p className="mb-2">
We recognize our legal obligations to protect personal information collected
during the course of carrying on business. We have therefore made reasonable
arrangements to secure against the unauthorized access, collection, use,
disclosure, copying, modification, disposal, or destruction of personal
information. All interactions with our services use the Transport Layer
Security/Secure Sockets Layer (TLS/SSL) protocol. We use a third-party,
industry-accepted Payment Gateway to securely process credit card transactions.
</p>

<h2 className="text-3xl mt-3 mb-3">Other Websites</h2>

<p className="mb-2">
Please note that our website and our online services may contain links to other
websites which are provided as a convenience for visitors to our website or
users of our services only. Any third party websites will have their own privacy
policies and practices, and we cannot be responsible for such third parties or
their websites.
</p>

<h2 className="text-3xl mt-3 mb-3">Requests for Access to Personal Information</h2>

<p className="mb-2">
The law permits individuals to submit written requests to us to provide them with:
</p>

<ul className="list-disc list-outside ml-8 mb-2">
    <li>access to their personal information, if any, under our custody or
        control;</li>
    <li>information about the purposes for which their personal information
        under our custody or control has been and is being used by us; and</li>
    <li>the names of persons or entities to whom, and the circumstances in
        which, their personal information has been and is being disclosed by
        us.</li>
</ul>

<p className="mb-2">
We would also note that your ability to access your personal information under
our control is not absolute and we reserve all our legal rights to not disclose
personal information should we be of the view that it is not appropriate or
reasonable to do so.
</p>

<h2 className="text-3xl mt-3 mb-3">Responses to Requests for Access to Personal Information</h2>

<p className="mb-2">
Our response to requests for access to personal information will in all cases be
in writing, and will confirm whether we are providing all or part of the
requested information, whether or not we are allowing access or providing
copies, and, if access is being provided, when that will be given.If access or
copies are refused by us, we will provide written reasons for such refusal and
the section of The Personal Information Protection and Electronic Documents Act
on which that refusal is based, along with the name of person at Cloto who can
answer questions about the refusal, and particulars of how the requesting 
individual can ask the Information and Privacy Commissioner of Canada to review
our decision.
</p>

<h2 className="text-3xl mt-3 mb-3">Requests for Correction of Personal Information</h2>

<p className="mb-2">
The law permits individuals to submit written requests to us to correct errors
or omissions in their personal information where that information is in our
custody or control. We do require that all such requests be in writing, with no
exceptions, and email is not considered to be in writing. We reserve the right
to require sufficient information and detail from the individual in question in
order to properly locate the information and provide a response.In the event
that an individual alleges inaccuracies, errors or omissions in the personal
information in our custody or control, we will either:
</p>

<ul className="list-disc list-outside ml-8 mb-2">
    <li>correct the personal information and, if reasonable to do so, send
        correction notifications to any other organizations to whom we disclosed
        the incorrect information; or</li>
    <li>decide not to correct the personal information but annotate the personal
        information that a correction was requested but not made.</li>
</ul>

<p className="mb-2">
Corrections or amendments will not be made to opinions, including expert or
professional opinions, as opposed to factual information, which may be corrected
if in error.
</p>

<h2 className="text-3xl mt-3 mb-3">Amendment of Policy</h2>

<p className="mb-2">
We reserve the right to amend this Policy from time to time as required and
without notice by posting an amended version on our website.
</p>

<h2 className="text-3xl mt-3 mb-3">Contacting or Communicating with Us</h2>

<p className="mb-2">
If you have any questions with respect to our policies concerning the handling
of your personal information, or if you wish to request access to, or correction
of, your personal information under our care and control, please contact us
at: <a href="mailto:admin@cloto.ca">admin@cloto.ca</a>.
</p>
<p className="mb-2">
Our Corporate Address:
</p>
<address className="ml-4 mb-8">
Cloto Inc.<br/>
243 – 1885 Clements Rd<br/>
Pickering, <abbr title="Ontario">ON</abbr>, L1W 3V4<br/>
</address>

        </div>
      </div>
    </main>
    <Footer></Footer>
    </div>
  )
}

export default PrivacyPolicy

